import React from 'react';
import './App.css';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Footer from "./components/Footer";
import Home from "./pages/Home"
import Contact from "./pages/Contact"
import About from "./pages/About"
import Links from "./pages/Links";
import Services from "./pages/Services"
import CookiePopup from "./components/CookiePopup";


const theme = createTheme();

const sections = [{title: 'Home', url: '/'}, {title: 'About', url: '/about'}, {
    title: 'Contact',
    url: '/contact'
}, {title: 'Services', url: '/services'},
    {title: 'Links and Resources', url: '/links'}];

export default function App() {
    return (
        <div className='App'>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Container maxWidth="lg">
                    <main>
                        <Router>
                            <Routes>
                                <Route exact path='/' element={<Home sections={sections}/>}/>
                                <Route exact path='/home' element={<Home sections={sections}/>}/>
                                <Route exact path='/about' element={<About sections={sections}/>}/>
                                <Route exact path='/contact' element={<Contact sections={sections}/>}/>
                                <Route exact path='/links' element={<Links sections={sections}/>}/>
                                <Route exact path='/services' element={<Services sections={sections}/>}/>
                                <Route path='*' element={<Home sections={sections}/>}/>
                            </Routes>
                        </Router>
                    </main>
                    <Footer/>
                </Container>
            </ThemeProvider>
            <CookiePopup/>

        </div>
    );
}
