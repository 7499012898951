import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            Copyright 2024 JoshuaAxtell.com
        </Typography>
    );
}

function Footer() {

    return (
        <Box component="footer" sx={{py: 6}}>
            <Container maxWidth="lg">
                <p align="center"></p>
                <div className="text-center"><a href="/contact">📞 [Contact Joshua]</a> | <a
                    href="mailto:josh.axtell@gmail.com">📧 [Email Joshua]</a> | 🌐 <a href="/">[Home]</a></div>
                <Copyright/>
            </Container>
        </Box>
    );
}

export default Footer;
