import Header from "../components/Header";
import React from "react";
import Container from '@mui/material/Container';

const About = (props) => (
    <>
        <Header title="About" sections={props.sections}/>
        <Container>
            <div className="full-width-centered, text-center">
                <picture>
                    <source media="(min-width: 650px)" srcSet="/image/profile.jpg"/>
                    <source media="(min-width: 330px) " srcSet="/image/profileSmall.jpg"/>
                    <source media="(min-width: 100px) " srcSet="/image/profileTiny.jpg"/>
                    <img src="/image/profile.jpg" alt="Joshua Axtell Profile Photo"/>
                </picture>
            </div>
            <h1 className="full-width-centered, text-center">About Joshua Axtell</h1>
            <p>Throughout my career journey, I've embraced diverse challenges and opportunities that have shaped my
                expertise. From founding a PC partnership in my teenage years to leading innovative projects at
                Thomson Reuters and beyond, each experience has been a stepping stone towards honing my skills and
                expanding my horizons. With each role, I've seized opportunities to deepen my knowledge and
                expertise. At CXTec, I immersed myself in database fundamentals, while at Thomson Reuters, I
                mastered Oracle Exadata and spearheaded a groundbreaking transition to clustered computing with
                Scala and Spark. My journey at Mindex further fueled my passion for innovation, leading to my
                transition to the Cloud Team and subsequent promotion to Cloud Architect. My journey in software
                development is defined by a relentless pursuit of excellence and a thirst for knowledge. I've
                pursued AWS certifications and embraced cloud-native technologies to stay at the forefront of
                industry innovation. At Astor & Sanders, I continue to embrace new challenges and opportunities to
                further expand my skill set.</p><p>As I reflect on my journey thus far, I'm proud of the impact I've
            made in leveraging technology to drive business success. Looking ahead, I'm excited to continue
            pushing
            boundaries, solving complex problems, and making meaningful contributions to the ever-evolving
            landscape
            of technology and software development.</p>
            <hr/>
            <p>I discovered my passion for programming at a young age, delving into the world of code with Logo in
                third grade. From the moment I learned to maneuver the turtle on the screen, I was captivated by the
                endless possibilities of software development. When I was a teenager I formed a partnership with a
                friend
                for PC and related work. We mostly focused on building PCs, but also did multiple network
                installations and built a handful of websites for local businesses.</p><p>After completing college
            and
            obtaining a degree in Computer Science, I began to focus more on software development. My first
            professional full-time position was for CXTec, where I worked on their internal ERP application
            written in Java and JSP with an Oracle database. During my tenure, I focused my efforts on improving
            my database knowledge, reading the Oracle manual cover to cover. Since the application leveraged a
            lot of code stored in the database, and because my focus in college was not on database, I knew I
            could be more effective in the role by improving my database skills. By the time I transitioned to
            my
            next position, my database skills had grown considerably.
        </p><p>After transitioning from CXTec about four years into my career, I began a near ten-year stint
            working
            for Thomson Reuters in their public records department. There a large portion of the business logic
            was
            stored in database functions, stored procedures, and triggers. I was able to leverage the skills
            learned
            at CXTec to quickly set myself as a leader in database programming. Thomson Reuters was leveraging
            Oracle Exadata servers, which provide columnar compression for optimized analytical processing, and
            I
            once again had an opportunity to grow my skills. I read up on Exadata specific feature and how to
            get
            the most out of the system. I can remember at one point when we were starting to have performance
            issues
            and called in a consultant from Oracle. The first thing they said is that they won't recommend new
            hardware and they'll find and help fix the issues in our system. After several days of reviewing our
            code and metrics, the recommendation was to buy new hardware. We had tuned the system so well that
            we
            were getting every ounce of performance that could be expected!</p><p>Towards the end of my tenure
            at
            Thomson Reuters, I oversaw a team project where we prototyped a rewrite of the entire pathway,
            transitioning from doing the heavy lifting in the database to instead using clustered computing
            using
            Scala and Spark. Another opportunity to take the skills I'd acquired and pivot into something
            related
            but new!</p><p>
            My next adventure took me to Mindex, where I was on a statement-of-work team for about three years.
            This
            gave me an opportunity to refresh many of the skills I had used earlier in my career, while still
            learning and adding new techniques and frameworks. Mindex provided encouragement to grow into the
            cloud
            space and get AWS certified. During that journey, a position on the newly formed Cloud Team within
            Mindex opened, and I soon transitioned to that team. While I was on that team I acquired multiple
            AWS
            certifications and continued to grow my cloud native skills, leading to being promoted to Cloud
            Architect.</p><p>My most recent full-time adventure has landed me with Astor &amp; Sanders, a firm
            contracting with the US Government, where I continue to leverage and grow my breadth and depth of
            skills.


        </p>
        </Container>
    </>
);

export default About;