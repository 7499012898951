import Header from "../components/Header";
import React from "react";
import Container from '@mui/material/Container';

const Home = (props) => (
        <>
            <Header title="Home" sections={props.sections}/>
            <Container>
                <h2 className="full-width-centered, text-center">Cloud Architect | Technology Enthusiast</h2>
                <hr/>
                <h2>Welcome to My Digital Playground!</h2>
                <p>I'm passionate about leveraging technology to build innovative solutions and create meaningful
                    experiences. Explore my world of code and creativity, where every line tells a story.</p>
                <hr/>
                <h2>What I Do:</h2>
                <ul>
                    <li>Cloud Native Architecture: Levering the latest cloud offerings to design scalable and resilient
                        applications with a low total cost of ownership.
                    </li>
                    <li>Big Data Processing: Using data lakes and big data technologies to turn data into answers.</li>
                    <li>Software Engineering: Crafting robust and scalable software solutions tailored to meet diverse
                        needs.
                    </li>
                    <li>API Development: Designing backend APIs for use with interactive websites and software as a
                        service.
                    </li>
                    <li>Problem Solving: Solving complex technical challenges with creativity and precision.</li>
                    <li>Performance Tuning: Improve existing solutions to be more efficient and cost effective.</li>
                    <li>Technology Integration: Seamlessly integrating cutting-edge technologies to drive efficiency and
                        innovation.
                    </li>
                </ul>
                <hr/>
                <h2>Key Skills:</h2>
                <ul>
                    <li>Programming Languages: Proficient in Python, PySpark, Java, Scala, Spark, JavaScript, shell
                        scripting, SQL.
                    </li>
                    <li>Backend Development: Skilled in server-side development using both common frameworks and creating
                        bespoke applications.
                    </li>
                    <li>Database Management: Expertise in SQL and NoSQL databases (e.g., Oracle, MySQL, MongoDB,
                        DynamoDB).
                    </li>
                    <li>Web Technologies: Experienced in HTML5, CSS3, JavaScript frameworks (e.g., React, Angular).</li>
                    <li>Version Control: Proficient in Git and Mercurial for collaborative development.</li>
                    <li>DevOps: Familiar with Docker, Kubernetes, and CI/CD pipelines for seamless deployment.</li>
                </ul>
                <hr/>
                <h2>Let's Connect:</h2>

                <p>Get in touch to discuss potential collaborations, projects, or just to say hello!</p>

                <p>Email: <a href='mailto:josh.axtell@gmail.com'>Josh.Axtell@gmail.com</a><br/>
                    LinkedIn: <a href='https://www.linkedin.com/in/joshaxtell/'>linkedin.com/in/joshaxtell/</a>
                    <br/>
                    Contact Form: <a href='/contact'>Contact Joshua</a></p>
                <hr/>
                <p>Ready to embark on a digital journey together? Let's build something amazing!</p>
            </Container>
        </>
    )
;
export default Home;