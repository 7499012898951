import Header from "../components/Header";
import MyFormLogic from "../components/ContactForm";
import Container from '@mui/material/Container';
import React, {useState, useEffect} from 'react';
import {CircularProgress} from "@mui/material";
import Box from "@mui/material/Box";

const MyForm = () => {
    const {formData, handleChange, handleSubmit, error, result} = MyFormLogic();
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [submitInProgress, setSubmitInProgress] = useState(false);

    const handleButtonClick = () => {
        setIsSubmitDisabled(true);
        handleSubmit(setSubmitInProgress);
    };

    useEffect(() => {
        //console.log('data changed');
        if (!error) {
            setIsSubmitDisabled(false);
        }
    }, [error]);

    formData.subject = "contact_form";
    return (
        <form onSubmit={handleSubmit}>
            <label>
                Name:
                <input type="text" name="name" className="full-width" value={formData.name} onChange={handleChange}/>
            </label>
            <label>
                Email:
                <input type="text" name="email" className="full-width" value={formData.email} onChange={handleChange}/>
            </label>
            <label>
                Phone Number (Optional):
                <input type="text" name="phone" className="full-width"
                       value={formData.phone}
                       onChange={handleChange}/>
            </label>
            <label>
                Message:
                <textarea name="message" className="full-width" value={formData.message} onChange={handleChange}/>
            </label>
            <button type="button" onClick={handleButtonClick} disabled={isSubmitDisabled}>Submit</button>
            <div className="classes-table">{error && <p style={{color: 'red'}}>{error}</p>}
                {(!error || result) && <p>{result}</p>}
                {submitInProgress &&
                    <Box className="classes-table">
                        <CircularProgress className="classes-table"/>
                    </Box>
                }
            </div>
        </form>
    );
};

const Contact = (props) => (
    <>
        <Header title="Contact Joshua" sections={props.sections}/>
        <Container>
            <div>
                <div align="center"><a href="tel:3155915483">Call</a> or&nbsp;
                    <a href="sms:3155915483">text us</a> at <a href="tel:3155915483">(315) 591-5483</a></div>
                <br/><br/>
                <div align="center">Or fill out and submit the form below</div>
                <MyForm/>
            </div>
        </Container>
    </>
);

export default Contact;