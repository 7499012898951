import Header from "../components/Header";
import React from "react";
import Container from '@mui/material/Container';

const Links = (props) => (
    <>
        <Header title="Links and Resources" sections={props.sections}/>
        <Container>
            <div>
                <p>Email: <a href='mailto:josh.axtell@gmail.com'>Josh.Axtell@gmail.com</a><br/>
                    LinkedIn: <a
                        href='https://www.linkedin.com/in/joshaxtell/'>https://www.linkedin.com/in/joshaxtell/</a>
                    <br/>
                    Contact Form: <a href='/contact'>Contact Joshua</a></p>
            </div>
        </Container>
    </>
);

export default Links;